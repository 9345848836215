require("@rails/ujs").start()
require("@rails/activestorage").start()

import 'bootstrap/dist/js/bootstrap.js';
import '../../../vendor/assets/javascripts//bootstrap-datepicker.js'
import '../../../vendor/assets/javascripts/bootstrap-confirmation.js'

$(document).ready(function() {
  $('[data-toggle=confirmation]').confirmation({
    rootSelector: '[data-toggle=confirmation]',
    title: 'Are you sure?'
  });

  $(function () {
    $('.popover-btn').popover({
      container: 'body'
    })
  });

  $(document).on("click", ".popover-close" , function(){
    $(this).parents(".popover").popover('hide');
  });
});